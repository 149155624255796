import { create } from 'zustand'
import { EventProps } from './Steps/Step1' // todo: move to here
import { persist } from 'zustand/middleware'
import { fileToDataURL } from '@src/utils/helpers'

interface IBasketItem {
  title: string
  amount: number
  date: string
  price: number
  id: string
}

interface IFoodTimingInstructions {
  date: string
  message: string
}

export interface LocalStoreProps {
  basket: IBasketItem[]
  selectedStartDate: string
  selectedEndDate: string
  bookWholeDay: boolean
  startTime: string
  endTime: string
  numGuests: number
  meetingRoom: string
  repeatEvent: boolean
  foodTimingInstructions: IFoodTimingInstructions[]
  expire: string
}

interface IFoodStore {
  basket: IBasketItem[]
  currentStep: number
  selectedStartDate: string
  selectedEndDate: string
  bookWholeDay: boolean
  startTime: string
  endTime: string
  numGuests: number
  meetingRoom: string
  meetingRoomId: string
  repeatEvent: boolean
  foodTimingInstructions: IFoodTimingInstructions[]
  ssn: string
  meetingName: string
  contactName: string
  contactTel: string
  contactEmail: string
  comments: string
  eventData: EventProps[]
  eventType: undefined | string
  isInvalid: boolean
  companyName: string
  eventCode: number
  totalPrice: number,
  venuePrice: number,
  program: string | undefined,
  programFileName: string,
  PDFData: string | ArrayBuffer | null,
  answers: string[][],
  LargeEventName: string,
  LargeEventTechnicalNeeds: string,
  expire: Date
  setAnswers: (value: string[][]) => void
  setPDFData: (value: string | ArrayBuffer | null) => void
  setProgram: (value: File | undefined) => void
  setIsInvalid: (value: boolean) => void
  setEventData: (value: EventProps[]) => void
  setMeetingName: (value: string) => void
  setContactName: (value: string) => void
  setContactTel: (value: string) => void
  setContactEmail: (value: string) => void
  setComments: (value: string) => void
  setSsn: (value: string) => void
  setRepeatEvent: (value: boolean) => void
  addToBasket: (value: IBasketItem) => void
  updateItem: (value: IBasketItem) => void
  removeItem: (value: string) => void
  setBasket: (value: IBasketItem[]) => void
  setCurrentStep: (value: number) => void
  setSelectedStartDate: (value: string) => void
  setSelectedEndDate: (value: string) => void
  setBookWholeDay: (value: boolean) => void
  setStartTime: (value: string) => void
  setEndTime: (value: string) => void
  setNumGuests: (value: number) => void
  setMeetingRoom: (value: string) => void
  setMeetingRoomId: (value: string) => void
  setEventType: (value: string) => void
  addFoodTimingInstructions: (value: IFoodTimingInstructions) => void
  updateFoodTimingInstructions: (value: IFoodTimingInstructions) => void
  setFoodTimingInstructions: (value: IFoodTimingInstructions[]) => void
  setCompanyName: (value: string) => void
  setEventCode: (value: number) => void
  setTotalPrice: (value: number) => void
  setVenuePrice: (value: number) => void
  clearStore: () => void
}

export const useStore = create(
  persist<IFoodStore>(set => ({
    basket: [],
    currentStep: 0,
    selectedStartDate: '',
    selectedEndDate: '',
    bookWholeDay: false,
    startTime: '',
    endTime: '',
    numGuests: 0,
    meetingRoom: '',
    meetingRoomId: '',
    repeatEvent: false,
    foodTimingInstructions: [],
    isInvalid: false,
    eventType: undefined,
    companyName: '',
    eventCode: 0,
    totalPrice: 0,
    venuePrice: 0,
    programFileName: '',
    program: undefined,
    PDFData: '',
    answers: [],
    expire: new Date(),
    setAnswers: (value: string[][]) => {
      set({ answers: value })
      set({ expire: new Date() })
    },
    setPDFData: (value: string | ArrayBuffer | null) => {
      set({ PDFData: value })
      set({ expire: new Date() })
    },
    setProgram: async (value: File | undefined) => {
      set({ programFileName: value?.name || '' });
      if (value) {
        const dataURL = await fileToDataURL(value);
        set({ program: dataURL });
      } else {
        set({ program: undefined });
      }
      set({ expire: new Date() })
    },    
    setVenuePrice: (value: number) => {set({ venuePrice: value })
  set({ expire: new Date() })},
    setIsInvalid: (value: boolean) => {set({ isInvalid: value })
  set({ expire: new Date() })},
    setBasket: (value: IBasketItem[]) => {set({ basket: value })
  set({ expire: new Date() })},
    ssn: '',
    setSsn: (value: string) => {set({ ssn: value.includes('-') ? value.replace(/-/g, '') : value })
  set({ expire: new Date() })},
    meetingName: '',
    setMeetingName: (value: string) => {set({ meetingName: value })
  set({ expire: new Date() })},
    contactName: '',
    setContactName: (value: string) => {set({ contactName: value })
  set({ expire: new Date() })},
    contactTel: '',
    setContactTel: (value: string) => {set({ contactTel: value })
  set({ expire: new Date() })},
    contactEmail: '',
    setContactEmail: (value: string) => {set({ contactEmail: value })
  set({ expire: new Date() })},
    comments: '',
    setComments: (value: string) => {set({ comments: value })
  set({ expire: new Date() })},
    eventData: [],
    setEventData: (value: EventProps[]) => {set({ eventData: value })
  set({ expire: new Date() })},
    LargeEventName: '',
    LargeEventTechnicalNeeds: '',

    updateFoodTimingInstructions: (newItem: IFoodTimingInstructions) => {
      set(state => ({
        foodTimingInstructions: state.foodTimingInstructions.map(item => {
          if (item.date === newItem.date) {
            return {
              ...item,
              date: newItem.date,
              message: newItem.message,
            }
          } else {
            return item
          }
        }),
      }))
      set({ expire: new Date() })
    },
    addFoodTimingInstructions: (item: IFoodTimingInstructions) => {
      set(state => ({
        foodTimingInstructions: [
          {
            date: item.date,
            message: item.message,
          },
          ...state.foodTimingInstructions,
        ],
      }))
      set({ expire: new Date() })
    },
    addToBasket: (item: IBasketItem) => {
      set(state => ({
        basket: [
          {
            title: item.title,
            amount: item.amount,
            date: item.date,
            price: item.price,
            id: item.id,
          },
          ...state.basket,
        ],
      }))
      set({ expire: new Date() })
    },
    removeItem: (id: string) => {
      set(state => ({
        basket: state.basket.filter(item => item.id !== id),
      }))
      set({ expire: new Date() })
    },
    updateItem: (basketItem: IBasketItem) => {
      set(state => ({
        basket: state.basket.map(item => {
          if (item.id === basketItem.id) {
            return {
              ...item,
              title: basketItem.title,
              amount: basketItem.amount,
              date: basketItem.date,
              id: basketItem.id,
            }
          } else {
            return item
          }
        }),
      }))
      set({ expire: new Date() })
    },

    setCurrentStep: (step: number) => {
      set({ currentStep: step })
      set({ expire: new Date() })
    },
    setSelectedStartDate: (date: string) => {
      set({ selectedStartDate: date })
      set({ expire: new Date() })
    },
    setSelectedEndDate: (date: string) => {
      set({ selectedEndDate: date })
      set({ expire: new Date() })
    },
    setBookWholeDay: (value: boolean) => {
      set({ bookWholeDay: value })
      set({ expire: new Date() })
    },
    setStartTime: (time: string) => {
      set({ startTime: time })
      set({ expire: new Date() })
    },
    setEndTime: (time: string) => {
      set({ endTime: time })
      set({ expire: new Date() })
    },
    setNumGuests: (guests: number) => {
      set({ numGuests: guests })
      set({ expire: new Date() })
    },
    setMeetingRoom: (room: string) => {
      set({ meetingRoom: room })
      set({ expire: new Date() })
    },
    setMeetingRoomId: (id: string) => {
      set({ meetingRoomId: id })
      set({ expire: new Date() })
    },
    setRepeatEvent: (value: boolean) => {
      set({ repeatEvent: value })
      set({ expire: new Date() })
    },
    setFoodTimingInstructions: (value: IFoodTimingInstructions[]) => {
      set({ foodTimingInstructions: value })
      set({ expire: new Date() })
    },
    setEventType: (value: string) => {
      set({ eventType: value })
      set({ expire: new Date() })
    },
    setCompanyName: (value: string) => {
      set({ companyName: value })
      set({ expire: new Date() })
    },
    setEventCode: (value: number) => {
      set({ eventCode: value })
      set({ expire: new Date() })
    },
    setTotalPrice: (value: number) => {
      set({ totalPrice: value })
      set({ expire: new Date() })
    },
    clearStore: () => set({
      basket: [],
      currentStep: 0,
      selectedStartDate: '',
      selectedEndDate: '',
      bookWholeDay: false,
      startTime: '',
      endTime: '',
      numGuests: 0,
      meetingRoom: '',
      meetingRoomId: '',
      repeatEvent: false,
      foodTimingInstructions: [],
      isInvalid: false,
      eventType: undefined,
      companyName: '',
      eventCode: 0,
      totalPrice: 0,
      venuePrice: 0,
      program: undefined,
      PDFData: '',
      answers: [],
      ssn: '',
      meetingName: '',
      contactName: '',
      contactTel: '',
      contactEmail: '',
      comments: '',
      eventData: [],
      LargeEventName: '',
      LargeEventTechnicalNeeds: '',
    })
  }), { 
    name: 'stepStorage',
  }))

// if more than 1 hour has passed since last update, clear store
const { expire } = useStore.getState()
const now = new Date()
const lastUpdate = expire ? new Date(expire) : new Date()
const diff = now.getTime() - lastUpdate.getTime()
const minutes = Math.floor(diff / 1000 / 60)
if (minutes > 60) {
  useStore.getState().clearStore()
}
